import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';
import GroupCard from './groupCard';
import ButtonSecondary from '../utilities/buttonSecondary';
import LessonData from './lessonData';


function GroupsScreen() {

  // Define variables
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile);

  let lessonsStarted = 0;
  let lessonsCompleted = 0;
  let averageRetries = 0;
  let averageScore = 0;
  let lessonOverTimeData;

  if( profileData.activityData ) {
    lessonsStarted = profileData.activityData.lessonsStarted;
    lessonsCompleted = profileData.activityData.lessonsCompleted;
    averageRetries = profileData.activityData.averageRetries;
    averageScore = profileData.activityData.averageScore;
    lessonOverTimeData = profileData.activityData.lessonOverTimeData;
  }


  // Screen componets
  let addGroupElement;
  if( profileData.role === 'superAdmin' ) {
    addGroupElement = (
      <ButtonSecondary
        value={'Add Group'}
        handleButtonClick={() => navigate('/groups/new')}
      />
    );
  }

  let blankStateElement;
  if( profileData.groups.length === 0 ) {
    blankStateElement = (
      <div class='flex-column border-element-round padding-secondary' style={{textAlign: 'center'}}>
        <br />
        <p><strong>There are not any Groups yet</strong></p>
        <p>Create your first group by clicking the Add Group button above</p>
        <br />
      </div>
    );
  }

  let lessonGraphElement;
  if( lessonOverTimeData ) {
    lessonGraphElement = (
      <div style={{marginBottom: '60px'}}>
        <LessonData
          data={lessonOverTimeData}
          lessonsStarted={lessonsStarted}
          lessonsCompleted={lessonsCompleted}
          averageRetries={averageRetries}
          averageScore={averageScore}
        />
      </div>
    );
  }


  return(
    <div class='flex-column'>

      <Header />
      <OrganizationCard showAdditionlInfo={true} />

      <div class='body-container'>

        {lessonGraphElement}

        <div>
          <div class='flex-row' style={{marginBottom: '15px'}}>
            <h2>Groups</h2>
            {addGroupElement}
          </div>
          {blankStateElement}
          <div class='flex-row flex-wrap'>
            {profileData.groups.map((group, index) =>
              <GroupCard
                group={group}
                key={index}
                navigate={navigate}
              />
            )}
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default GroupsScreen;