function TdEditInfo(props) {

  // Define variables
  const object = props.object;
  const editInfoFunction = props.editInfoFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => editInfoFunction(object)}
        class='icon icon-edit'
        title='Edit info'
      />
    </td>
  );
}

export default TdEditInfo;