import Table from "../utilities/table/table";


function ViewLearnerCourse(props) {

  // Define variables
  const course = props.course;
  const headings = props.headings;
  const firstName = props.firstName;
  const isIelts = course.isIelts;
  const score = course.score;


  // Screen components
  let dateWord = 'Started';
  let date = course.createdAt;
  if( course.status === 'complete' ) {
    dateWord = 'Completed';
    date = course.updatedAt;
  } else if( course.status === 'cancelled' ) {
    dateWord = 'Cancelled';
    date = course.updatedAt;
  }

  let scoreElement;
  if( score ) {
    const displayCourseScore = `${course.score}${isIelts ? null : '%'}`;
    scoreElement = <p>Course score: {displayCourseScore}</p>;
  }


  return (
    <div style={{marginBottom: '60px'}}>
      <h4>{course.title}</h4>
      <p>Course status: {course.status}</p>
      {scoreElement}
      <p class="gray-text seconday-info" style={{fontSize: '.9rem'}}>
        {course.courseLessonsCompleted}
        <span style={{marginLeft: '1px', marginRight: '1px'}}>/</span>
        {course.courseLessonTotal} lessons completed
      </p>
      <p class="gray-text seconday-info" style={{marginBottom: '15px', fontSize: '.9rem'}}>
        {dateWord} {date}
      </p>
      <Table
        headings={headings}
        data={course.lessons}
        filename={`Lesson Data - ${course.title} - ${firstName}`}
      />
    </div>
  );
}

export default ViewLearnerCourse;