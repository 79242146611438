import Table from "../utilities/table/table";
import ViewLearnerCourse from "./viewLearnerCourse";


function ViewLearnerCourses(props) {

  // Define variables
  const headings = props.headings;
  const courseData = props.courseData;
  const firstName = props.firstName;


  // Screen components
  let blankStateElement;
  if( !courseData || courseData.length === 0 ) {
    blankStateElement = <p>No Courses have been started or completed</p>;
  }


  return (
    <div>
      {blankStateElement}
      {courseData.map((course, index) =>
        <ViewLearnerCourse
          key={`course-${index}`}
          course={course}
          headings={headings}
          firstName={firstName}
        />
      )}
    </div>
  );
}

export default ViewLearnerCourses;