import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import apiCall from '../../helperFunctions/apiCall';
import profileSlice from '../../store/profile';
import ButtonPrimary from '../utilities/buttonPrimary';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';


function GroupForm(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function clearInitialState() {
    dispatch(profileSlice.actions.clearInitialState());
  }


  // Define variables
  const navigate = useNavigate();
  const apiMethodGet = props.apiMethodGet;
  const apiMethodSave = props.apiMethodSave;
  const groupId = props.groupId;
  // const groupData = props.groupData;
  // const setGroupData = props.setGroupData;
  const heading = props.heading;

  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';
  const organizationId = useSelector((state) => state.profile.organizationId);
  const [groupData, setGroupData] = useState({});

  const name = groupData.name;
  const slug = groupData.slug;
  const seatsEnabled = groupData.seatsEnabled;
  const seatsRemaining = groupData.seatsRemaining;
  const seatAllocation = groupData.seatAllocation;
  const managers = groupData.managers ? groupData.managers : [];

  const [initalized, setInitalized] = useState(false);
  const [assignedManagers, setAssignedManagers] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(null);

  const backPath = groupId ? `/groups/${groupId}` : '/';


  // Page load actions
  useEffect(() => {

    if( !isSuperAdmin ) { navigate('/') }

    if( !initalized ) {

      apiCall(apiMethodGet, {id: groupId, organizationId: organizationId}).then((response) => {
        if( response.success ) {

          const data = response.data;
          const assignedManagerIds = [];

          data.managers.map((manager) => {
            if( manager.isGroupManager ) {
              assignedManagerIds.push(manager.id.toString());
            }
          });

          setInitalized(true);
          setAssignedManagers(assignedManagerIds);
          setGroupData(data);

        } else {
          toast.error(response.error);
        }
      });

    }

  }, [groupId]);


  // Define functions
  function handleGroupNameChange(value) {
    setGroupData({
      ...groupData,
      name: value,
      slug: value.toLowerCase().replaceAll(' ', '-').replaceAll("'", '')
    });
  }

  function handleSlugChange(value) {
    setGroupData({
      ...groupData,
      slug: value.toLowerCase().replaceAll(' ', '-').replaceAll("'", '')
    });
  }

  function handleSeatAllocationChange(value) {

    // setSeatAllocation(value);
    setGroupData({
      ...groupData,
      seatAllocation: value
    });

    if( seatsRemaining && value > seatsRemaining ) {
      setTimeout(() => {
        toast.error('Please choose an amount that is not more than the remaining seats');
      }, 200);
    }

  }

  function handleAssignMemberClick(value) {
    if( assignedManagers.includes(value) ) {
      const array = [...assignedManagers];
      const index = assignedManagers.indexOf(value);
      if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
      }
      setAssignedManagers(array);
    } else {
      setAssignedManagers(assignedManagers => [...assignedManagers, value]);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    setButtonLoading(true);
    let errorMessage;

    if( seatAllocation && seatAllocation > seatsRemaining ) {
      errorMessage = 'Please select a seat allocation amount that is not more than the remaining seats';
    }

    if( !name ) {
      errorMessage = 'Please enter a group name';
    }

    if( errorMessage ) {
      toast.error(errorMessage);
      setButtonLoading(false);
      return;
    }

    const payload = {
      organization_id: organizationId,
      id: groupId,
      group_name: name,
      group_name_slug: slug,
      seat_allocation: seatAllocation,
      assigned_managers: assignedManagers
    }

    apiCall(apiMethodSave, payload).then((response) => {
      if( response.success ) {
        clearInitialState();
        toast.success('Group saved successfully');
        navigate(backPath, {state: {organizationId: organizationId}});
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });

  }


  // Screen componets
  let seatsElement;
  if( seatsEnabled ) {

    let seatsRemainingElement;
    if( seatsRemaining ) {
      seatsRemainingElement = <small><i> {seatsRemaining} seats remaining</i></small>;
    }

    seatsElement = (
      <div class='field'>
        <label>Seat Allocation <small><i>( optional )</i></small></label>
        <input
          autoComplete='off'
          name="seatAllocation"
          type="text"
          value={seatAllocation}
          onChange={event => handleSeatAllocationChange(event.target.value)}
          title="Assign seat allocation"
          style={{width: '75px'}}
        />
        {seatsRemainingElement}
      </div>
    );
  }

  let managerBlankStateElement;
  if( !managers || managers.length === 0 ) {
    managerBlankStateElement = (
      <p><i>There are no Managers yet. You can invite Managers by going to the Staff tab.</i></p>
    )
  }


  return (
    <div class='flex-column'>

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        <h2>{heading}</h2>

        <form onSubmit={handleSubmit}>

          <div class='field'>
            <label>Group Name</label>
            <input
              autoComplete='off'
              name="name"
              type="text"
              value={name}
              onChange={event => handleGroupNameChange(event.target.value)}
              minLength="4"
              title="Add a group name"
              required
              placeholder='Group name'
            />
          </div>

          <div class='field'>
            <label>Group Name Slug</label>
            <input
              autoComplete='off'
              name="slug"
              type="text"
              value={slug}
              onChange={event => handleSlugChange(event.target.value)}
              minLength="4"
              title="Add a slug"
              required
              placeholder='Group name slug'
            />
          </div>

          {seatsElement}

          <div class='field'>
            <label>Assign Managers</label>
            <div style={{marginTop: '10px', marginBottom: '45px'}}>
              {managerBlankStateElement}
              {managers?.map((manager, index) =>
                <div
                  class='flex-row'
                  style={{justifyContent: 'flex-start', marginBottom: '5px'}}
                  key={`checkbox-container-${manager.id}`}
                >
                  <input
                    type='checkbox'
                    value={manager.id}
                    id={`checkbox-${manager.id}`}
                    onChange={event => handleAssignMemberClick(event.target.value)}
                    defaultChecked={manager.isGroupManager}
                  />
                  <label htmlFor={`checkbox-${manager.id}`}>{manager.name} <small><i>( {manager.email} )</i></small></label>
                </div>
              )}
            </div>
          </div>

          <div class='flex-row' style={{justifyContent: 'flex-start'}}>

            <ButtonPrimary
              value={'Save'}
              loading={buttonLoading}
            />

            <button
              type='button'
              class='edit-link'
              style={{marginLeft: '90px'}}
              onClick={() => navigate(backPath)}
            >Cancel</button>

          </div>

        </form>
        </div>
      <Footer />
    </div>
  );
}

export default GroupForm;