function TdView(props) {

  // Define variables
  const id = props.id;
  const viewFunction = props.viewFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => viewFunction(id)}
        class='icon icon-view-fill'
        title='View'
      />
    </td>
  );
}

export default TdView;