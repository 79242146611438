import CourseUnhas from './courseUnhas';


function CourseDataCellUnhas(props) {

  // Define variables
  const unhasCourseData = props.unhasCourseData;
  const courseOneData = unhasCourseData[0];
  const courseTwoData = unhasCourseData[1];
  const courseThreeData = unhasCourseData[2];


  return (
    <div class='table-learner-row-data'>
      <CourseUnhas courseData={courseOneData} />
      <CourseUnhas courseData={courseTwoData} />
      <CourseUnhas courseData={courseThreeData} />
    </div>
  );
}

export default CourseDataCellUnhas;