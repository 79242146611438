function TdRemoveSeat(props) {

  // Define variables
  const id = props.id;
  const removeSeatFunction = props.removeSeatFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => removeSeatFunction(id)}
        class='icon icon-cancel-fill'
        title='Remove seat'
      />
    </td>
  );
}

export default TdRemoveSeat;