import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { Toaster } from 'react-hot-toast';
import PrivateRoute from "./components/utilities/auth/privateRoute";

import Login from './components/start/login';
import SetPassword from './components/start/setPassword';

import GroupsScreen from './components/groups';
import Group from './components/groups/group';
import AddGroup from './components/groups/addGroup';
import EditGroup from './components/groups/editGroup';

import StaffScreen from './components/staff';
import AddStaff from './components/staff/addStaff';

import NotFound from './components/start/notFound';


function App() {
  return (
    <BrowserRouter>
    <Toaster />
      <RoutesF />
    </BrowserRouter>
  );
}

function RoutesF() {
  return (
    <Routes>

      <Route path="/login" element={<Login />} />
      <Route path="/set-password" element={<SetPassword />} />

      <Route path="/" element={<PrivateRoute component={GroupsScreen} />} />
      <Route path="/groups/new" element={<PrivateRoute component={AddGroup} />} />
      <Route path="/groups/:id/edit" element={<PrivateRoute component={EditGroup} />} />
      <Route path="/groups/:id" element={<PrivateRoute component={Group} />} />

      <Route path="/staff" element={<PrivateRoute component={StaffScreen} />} />
      <Route path="/staff/new" element={<PrivateRoute component={AddStaff} />} />

      <Route path="*" element={<NotFound />} />

    </Routes>
  );

}

export default App;