import React from 'react';
import { useState } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { editLearnerPassword, sendPasswordResetEmail } from '../utilities/auth/authAPI';
import ButtonClose from '../utilities/buttonClose';
import ButtonPrimary from '../utilities/buttonPrimary';
import InputPassword from '../utilities/forms/inputPassword';


function EditPassword(props) {

  // Define variables
  const object = props.object;
  const id = object.id;
  const closeModal = props.closeModal;
  const [newPassword, setNewPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);


  // Define functions
  function handleButtonClick() {

    if( newPassword.length < 8 ) {
      toast.error('Please enter a password that is at least 8 characters long');
      return;
    }

    setButtonLoading(true);

    const payload = {
      id: id,
      new_password: newPassword
    };

    apiCall(editLearnerPassword, payload).then((response) => {
      if( response.success ) {
        closeModal();
        toast.success('Password successfully updated')
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(false), 400);
    });
  }

  function confirmSendPassordReset() {
    if (window.confirm('Are you sure you want to send a password reset email?')) {
      sendPasswordReset();
    }
  }

  function sendPasswordReset() {
    apiCall(sendPasswordResetEmail, {id: id}).then((response) => {
      if( response.success ) {
        closeModal();
        toast.success('Password reset email sent')
      } else {
        toast.error(response.error);
      }
    });
  }


  return(
    <div class='modal-wrapper'>
      <div class='modal-container'>

        <div class='modal-header'>

          <div>
            <h4>{object.firstName} {object.lastName}</h4>
            <p>{object.email}</p>
          </div>

          <ButtonClose closeAction={closeModal} />

        </div>

        <div class='modal-body'>

          <div style={{margin: '30px 0 15px 0'}}>
            <p style={{marginBottom: '5px'}}>
              <strong>New Password</strong>
            </p>
            <div class='field'>
              <InputPassword
                value={newPassword}
                handleOnChange={setNewPassword}
              />
            </div>
          </div>

          <div class='flex-row' style={{justifyContent: 'flex-start'}}>
            <ButtonPrimary
              value={'Save'}
              loading={buttonLoading}
              handleButtonClick={handleButtonClick}
            />

            <button
              type='button'
              class='link-secondary'
              style={{marginLeft: '30px'}}
              onClick={() => confirmSendPassordReset() }
            >or send password reset email</button>
          </div>

        </div>

      </div>
    </div>
  );
}

export default EditPassword;