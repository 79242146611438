import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import ButtonPrimary from '../utilities/buttonPrimary';


function FormStaff(props) {

  // Define variables
  const navigate = useNavigate();
  const groups = useSelector((state) => state.profile.groups);
  const organizationId = useSelector((state) => state.profile.organizationId);

  const apiMethod = props.apiMethod;
  const staffObject = props.staffObject;
  const successMessage = props.successMessage || 'Staff successfully created';
  const id = props.id || null;
  const onCompleteFunction = props.onCompleteFunction || null;

  const [firstName, setFirstName] = useState(staffObject.firstName);
  const [lastName, setLastName] = useState(staffObject.lastName);
  const [email, setEmail] = useState(staffObject.email);
  const [assignedGroups, setAssignedGroups] = useState(staffObject.assignedGroups);
  const [buttonLoading, setButtonLoading] = useState(null);


  // Define functions
  function handleGroupClick(value) {
    value = parseInt(value);
    if( assignedGroups.includes(value) ) {
      const array = [...assignedGroups];
      const index = assignedGroups.indexOf(value);
      if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
      }
      setAssignedGroups(array);
    } else {
      setAssignedGroups(assignedGroups => [...assignedGroups, value]);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    setButtonLoading(true);
    let errorMessage;

    if( !firstName || !email ) {
      errorMessage = 'Please fill out all the fields';
    }

    if( assignedGroups.length === 0 ) {
      errorMessage = 'Please assign at least one group';
    }

    if( errorMessage ) {
      toast.error(errorMessage);
      setButtonLoading(false);
      return;
    }

    const payload = {
      organization_id: organizationId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      assigned_groups: assignedGroups
    }

    if( id ) { payload.id = id }


    apiCall(apiMethod, payload).then((response) => {
      if( response.success ) {
        toast.success(successMessage);
        if( onCompleteFunction ) {
          onCompleteFunction();
        } else {
          navigate('/staff');
        }
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });

  }


  return(
    <form onSubmit={handleSubmit}>

      <div class='field'>
        <label>First name</label>
        <input
          autoComplete='off'
          name="firstName"
          type="text"
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          minLength="1"
          title="Add the first name"
          required
          placeholder='First name'
        />
      </div>

      <div class='field'>
        <label>Last name</label>
        <input
          autoComplete='off'
          name="lastName"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          minLength="1"
          title="Add the last name"
          placeholder='Last name'
        />
      </div>

      <div class='field'>
        <label>Email</label>
        <input
          autoComplete='off'
          name="email"
          type="email"
          value={email}
          onChange={event => setEmail(event.target.value)}
          minLength="6"
          title="Add the email"
          required
          placeholder='Email'
        />
      </div>

      <div class='field'>
        <label>Assign Groups</label>
        <div style={{marginTop: '10px'}}>
          {groups.map((group, index) =>
            <div
              class='flex-row'
              style={{justifyContent: 'flex-start', marginBottom: '5px'}}
              key={`checkbox-container-${group.id}`}
            >
              <input
                type='checkbox'
                value={group.id}
                id={`checkbox-${group.id}`}
                onChange={event => handleGroupClick(event.target.value)}
                checked={assignedGroups.includes(group.id)}
              />
              <label htmlFor={`checkbox-${group.id}`}>{group.name}</label>
            </div>
          )}
        </div>
      </div>

      <ButtonPrimary
        value={'Save'}
        loading={buttonLoading}
      />
    </form>
  );
}

export default FormStaff;