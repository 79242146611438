import Table from "../utilities/table/table";


function ViewLearnerLessons(props) {

  // Define variables
  const headings = props.headings;
  const data = props.data;
  const firstName = props.firstName;

  // <p>No lessons have been started or completed</p>

  return (
    <div>
      <Table
        headings={headings}
        data={data}
        filename={`Lesson Data - ${firstName}`}
        // title={'Lessons'}
      />
    </div>
  );
}

export default ViewLearnerLessons;