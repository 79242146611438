import React from "react";

function ButtonSecondary(props) {

  const buttonId = props.id || `button-${Date.now()}`;
  const value = props.value || 'Submit';
  let buttonClass = props.buttonClass || 'button secondary';
  const handleButtonClick = props.handleButtonClick || undefined;
  const disabled = props.loading || false;
  const title = props.title || `Tap to ${value}`;

  if( disabled ) {
    buttonClass = buttonClass + ' is-loading';
    value = '';
  }

  return (
    <div class="button-wrapper">
      <button
        id={buttonId}
        type="submit"
        class={buttonClass}
        value={value}
        onClick={handleButtonClick}
        title={title}
        disabled={disabled}
      >{value}</button>
    </div>
  );
}

export default ButtonSecondary;