function ProgressBar(props) {

  const width = `${props.percent}%` || '0%';

  return (
    <div class='progress-bar-outer'>
      <div class='progress-bar-inner' style={{width: width}}></div>
    </div>
  )
}

export default ProgressBar;