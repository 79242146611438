function Footer() {
  return (
    <footer>
      <p>
        Need help? Contact us at <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a>
      </p>
    </footer>
  )
}

export default Footer;