import React from 'react';
import { getNewGroupData, createGroup } from '../utilities/auth/authAPI';
import GroupForm from './groupForm';


function AddGroup() {


  return(
    <GroupForm
      apiMethodGet={getNewGroupData}
      apiMethodSave={createGroup}
      heading={'Add Group'}
    />
  );
}

export default AddGroup;