import Header from '../utilities/header';
import { Link } from "react-router-dom";


function NotFound() {

  return (
    <div class='body-wrapper flex-column'>
      <Header />

      <div class='body-container'>
        <br />
        <br />
        <br />
        <h1>Oops! We can't find that page.</h1>
        <br />
        <p>
          <Link to='/'>Head back home</Link>
        </p>
      </div>
    </div>
  );
}

export default NotFound;