import React from 'react';
import { useParams } from 'react-router-dom';
import { getGroupData, updateGroup } from '../utilities/auth/authAPI';

import GroupForm from './groupForm';


function EditGroup() {

  // Define variables
  const { id } = useParams();


  return (
    <GroupForm
      apiMethodGet={getGroupData}
      apiMethodSave={updateGroup}
      groupId={id}
      heading={'Edit Group'}
    />
  );
}

export default EditGroup;