// import ProgressBar from '../progressBar';
import CourseDataCellUnhas from './courseDataCellUnhas';
import CourseActivityBlock from './courseActivityBlock';


function CourseDataCell(props) {

  // Define variables
  const courseData = props.courseData;
  const courseToShow = courseData.courseToShow;
  const completedCourseCount = courseData.completedCourseCount;
  const pathwayData = props.pathwayData;
  const pathwayToShow = pathwayData.pathwayToShow;
  const completedPathwayCount = pathwayData.completedPathwayCount;
  const unhasCourseData = courseData.unhasCourseData;


  // Screen components
  let courseDataElement = <div class='table-learner-row-data'>-</div>;

  if( unhasCourseData ) {
    courseDataElement = <CourseDataCellUnhas unhasCourseData={unhasCourseData} />;
  } else {
    if( courseData ) {

      let activeCourseElement;
      let completedCoursesElement = <p><br /></p>;


      if( courseToShow ) {
        const progressDecimal = courseToShow.lessonsCompleted / courseToShow.lessonTotal;
        const progressPercent = Math.round(100 * progressDecimal);

        activeCourseElement = (
          <CourseActivityBlock
            title={courseToShow.title}
            percent={progressPercent}
            lessonComplete={courseToShow.lessonsCompleted}
            lessonTotal={courseToShow.lessonTotal}
          />
        );

        if( completedCourseCount && completedCourseCount > 0 ) {
          completedCoursesElement = <p class='gray-text'><br /><small>+ {completedCourseCount} more completed {(completedCourseCount) === 1 ? 'Course' : 'Courses'}</small></p>;
        }

      } else {

        if( pathwayToShow ) {
          const progressDecimal = pathwayToShow.lessonsCompleted / pathwayToShow.lessonTotal;
          const progressPercent = Math.round(100 * progressDecimal);

          activeCourseElement = (
            <CourseActivityBlock
              title={`${pathwayToShow.title} Pathway`}
              percent={progressPercent}
              lessonComplete={pathwayToShow.lessonsCompleted}
              lessonTotal={pathwayToShow.lessonTotal}
            />
          );

          if( completedPathwayCount && completedPathwayCount > 0 ) {
            completedCoursesElement = <p class='gray-text'><br /><small>+ {completedPathwayCount} more completed {(completedPathwayCount) === 1 ? 'Pathway' : 'Pathways'}</small></p>;
          }
        }

      }


      courseDataElement = (
        <div class='table-learner-row-data'>
          {activeCourseElement}
          {completedCoursesElement}
        </div>
      );
    }

  }


  return (
    <>{courseDataElement}</>
  );
}

export default CourseDataCell;