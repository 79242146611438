import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { isAuthed, saveToken } from '../utilities/auth/tokenHelpers';
import apiCall from '../../helperFunctions/apiCall';
import { setPassword } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import ButtonPrimary from '../utilities/buttonPrimary';
import InputPassword from '../utilities/forms/inputPassword';


function SetPassword() {

  // Define variables
  let initialAccountCode;
  if( URLSearchParams ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    initialAccountCode = urlParams.get('account-code');
  }

  const navigate = useNavigate();
  const [accountCode, setAccountCode] = useState(initialAccountCode);
  const [passwordValue, setPasswordValue] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [buttonLoading, setButtonLoading] = useState('');


  // Page load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !accountCode || !passwordValue || !agreeToTerms ) {
      toast.error('Please enter your account code, set a password, and accept the terms');
      return;
    }

    setButtonLoading(true);

    const payload = {
      account_code: accountCode,
      password: passwordValue,
      agree_to_terms: agreeToTerms
    };

    apiCall(setPassword, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        setTimeout(() => navigate('/'), 400);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }

  return (
    <div class='body-wrapper flex-column'>
      <Header />

      <div class='body-container'>
        <h1>Set Your Password</h1>

        <form onSubmit={handleSubmit}>

          <div class='field'>
            <input
              name="account_code"
              type="text"
              value={accountCode}
              onChange={event => setAccountCode(event.target.value)}
              required
              placeholder='Account Code'
              title="Enter your account code"
              autoComplete='off'
            />
          </div>

          <div class='field'>
            <InputPassword
              value={passwordValue}
              handleOnChange={setPasswordValue}
            />
          </div>

          <div class='field flex-row' style={{justifyContent: 'flex-start'}}>
            <input
              type='checkbox'
              id='agree_to_terms'
              onChange={event => setAgreeToTerms(event.target.checked)}
            />
            <label htmlFor='agree_to_terms'>
              I agree to the <a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
            </label>
          </div>

          <ButtonPrimary
            value={'Continue'}
            loading={buttonLoading}
          />

        </form>
      </div>
    </div>
  );
}

export default SetPassword;