import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getStaff } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import ButtonSecondary from '../utilities/buttonSecondary';
import Table from '../utilities/table/table';
import EditPassword from '../groups/editPassword';
import EditStaff from './editStaff';
import OrganizationCard from '../utilities/organizationCard';


function StaffScreen() {

  // Define variables
  const navigate = useNavigate();
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';
  const organizationId = useSelector((state) => state.profile.organizationId);
  const [loadingData, setLoadingData] = useState(true);
  const [staff, setStaff] = useState([]);
  const [editPasswordElement, setEditPasswordElement] = useState(null);
  const [editStaffElement, setEditStaffElement] = useState(null);

  const headings = [
    {name: 'firstName', display: 'First name'},
    {name: 'lastName', display: 'Last name'},
    {name: 'email', display: 'Email'},
    {name: 'role', display: 'Role'},
    {name: 'groups', display: 'Groups'},
  ]


  // Page load actions
  useEffect(() => {

    if( !isSuperAdmin ) { navigate('/') }

    getStaffInfo();

  }, []);


  // Define functions
  function getStaffInfo() {

    const payload = {
      organizationId: organizationId
    }

    apiCall(getStaff, payload).then((response) => {
      if( response.success ) {
        setStaff(response.data);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setLoadingData(false);
    });
  }

  function editPassword(object) {
    setEditPasswordElement(
      <EditPassword
        object={object}
        closeModal={() => setEditPasswordElement(null)}
      />
    );
  }

  function editStaff(object) {
    setEditStaffElement(
      <EditStaff
        object={object}
        closeModal={() => setEditStaffElement(null)}
        handleEditSuccess={handleEditSuccess}
      />
    );
  }

  function handleEditSuccess() {
    setLoadingData(true);
    setStaff([]);
    getStaffInfo();
    setTimeout(() => {
      setEditStaffElement(null);
    }, 700);
  }


  return(
    <div class='flex-column'>

      {editPasswordElement}
      {editStaffElement}

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        <div class='flex-row' style={{marginBottom: '15px'}}>

          <h2>Staff</h2>

          <ButtonSecondary
            value={'Add Staff'}
            handleButtonClick={() => navigate('/staff/new')}
          />

        </div>

        <Table
          headings={headings}
          data={staff}
          enableSearch={true}
          editPasswordFunction={editPassword}
          editInfoFunction={editStaff}
          isLoading={loadingData}
          blankStateWording={'There are no staff memebers yet'}
        />

      </div>

      <Footer />

    </div>
  );
}

export default StaffScreen;