import React from 'react';
import { useNavigate } from 'react-router-dom';


function Nav(props) {

  // Define variables
  const navigate = useNavigate();
  const profileData = props.profileData;
  const isSuperAdmin = props.isSuperAdmin;


  // Define functions
  function logout() {
    localStorage.removeItem("jwt-token");
    window.location = '/login';
  }


  // Screen componets
  let staffMembersElement;
  if( isSuperAdmin ) {
    staffMembersElement = (
      <button
        onClick={() => navigate('/staff')}
        style={{padding: '15px'}}
        class='link'
      >Staff</button>
    );
  }


  return(
      <div class='flex-row'>

        <div>

          <button
            onClick={() => navigate('/')}
            style={{padding: '15px'}}
            class='link'
          >Dashboard</button>

          {staffMembersElement}

        </div>

        <div class='flex-row' style={{
          padding: '30px',
          marginLeft: '30px',
          borderLeft: '1px solid #DCDCDC'
        }}>
          <a
            href='https://app.flowspeak.io'
            title='Go to FLOW Speak'
            class='flow-speak-link'
            target='_blank'
            rel='nofollow'
          >
            Go to FLOW Speak
          </a>
        </div>

        <div class='flex-row' style={{
          padding: '30px',
          // marginLeft: '30px',
          borderLeft: '1px solid #DCDCDC'
        }}>

          <span>{profileData.firstName}</span>

          <button
            class='icon icon-logout'
            style={{marginLeft: '15px'}}
            onClick={logout}
            title={'Logout'}
          />

        </div>
      </div>
  );
}

export default Nav;