import { useState } from "react";

function InputPassword(props) {

  // Define variables
  const name = props.name || 'password';
  const value = props.value;
  const handleOnChange = props.handleOnChange;
  const title = props.title || 'Password must be at least 8 characters';
  const placeholder = props.placeholder || 'Password';
  const [showPassword, setShowPassword] = useState(false);

  // Define functions
  function togglePassword() {
    setShowPassword(!showPassword);
  }


  return(
    <div style={{
      position: 'relative',
      display: 'inline-block'
    }}>

      <input
        autoComplete='off'
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={event => handleOnChange(event.target.value)}
        minLength="8"
        title={title}
        required
        placeholder={placeholder}
      />

      <button
        type='button'
        class={showPassword ? 'icon icon-view' : 'icon icon-view-fill'}
        onClick={() => togglePassword()}
        style={{
          position: 'absolute',
          top: '6px',
          right: '6px'
        }}
      />

    </div>
  );
}

export default InputPassword;