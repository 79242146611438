function TdEditPassword(props) {

  // Define variables
  const object = props.object;
  const editPasswordFunction = props.editPasswordFunction;


  return(
    <td class='td-icon'>
      <button
        onClick={() => editPasswordFunction(object)}
        class='icon icon-key'
        title='Edit password'
      />
    </td>
  );
}

export default TdEditPassword;