import React from 'react';


function GroupCard(props) {

  // Define variables
  const navigate = props.navigate;
  const group = props.group;
  const name = group.name;
  const totalSeats = group.totalSeats;
  const invitesAccepted = group.invitesAccepted;
  const invitesAcceptedPercent = group.invitesAcceptedPercent;


  // Screen components
  let totalSeatsElement;
  if( totalSeats ) {
    totalSeatsElement = <small> ( of {totalSeats} seats allocated )</small>;
  }

  let inviteElement;
  if( invitesAccepted && invitesAcceptedPercent ) {
    inviteElement = <p class='color-secondary'>{invitesAccepted} invites accepted ( {invitesAcceptedPercent} )</p>;
  }


  return(
    <button
      style={styles.container}
      class='border-element-round padding-secondary'
      onClick={() => navigate(`/groups/${group.id}`)}
      type={'button'}
    >

      <h3 style={{marginBottom: '15px'}}>{name}</h3>

      <p class='color-secondary'>
        {group.learnerCount} {group.learnerCount === 1 ? 'Learner' : 'Learners'}
        {totalSeatsElement}
      </p>
      {inviteElement}
      <p class='color-secondary'>
        {group.lessonsCompleted} {group.lessonsCompleted === 1 ? 'Lesson' : 'Lessons'} completed
      </p>

    </button>
  );
}

export default GroupCard;


// --

const styles = {
  container: {
    width: '49%',
    marginBottom: '30px'
  }
}