function TdBasic(props) {

  // Define variables
  const heading = props.heading;
  const object = props.object;
  let content = object[heading.name];

  if( heading.name === 'complete' && content === true ) {
    content = (
      <div class='flex-column'>
        <div class='icon icon-circle-ok'></div>
      </div>
    );
  }

  let tdClassName = heading.centered ? 'centered' : '';
  if( heading.name === 'id' ) {
    tdClassName += ' td-id';
  }


  return(
    <td class={tdClassName}>
      {content}
    </td>
  );
}

export default TdBasic;