import React from 'react';
import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getStaffInfo, updateStaff } from '../utilities/auth/authAPI';
import { Loading } from '../utilities/helpers';
import ButtonClose from '../utilities/buttonClose';
import FormStaff from './form';


function EditStaff(props) {

  // Define variables
  const object = props.object;
  const id = object.id;
  const closeModal = props.closeModal;
  const handleEditSuccess = props.handleEditSuccess;
  const [staffObject, setStaffObejct] = useState(null);


  // Page load actions
  useEffect(() => {
    const payload = {id: id};

    apiCall(getStaffInfo, payload).then((response) => {
      if( response.success ) {
        setStaffObejct(response.data);
      } else {
        toast.error(response.error);
      }
    });
  }, [id]);


  // Screen components
  let formElement = <Loading />;
  if( staffObject ) {
    formElement = (
      <FormStaff
        apiMethod={updateStaff}
        staffObject={staffObject}
        successMessage={'Staff successfully updated'}
        id={id}
        onCompleteFunction={handleEditSuccess}
      />
    );
  }


  return(
    <div class='modal-wrapper'>
      <div class='modal-container'>

        <div class='modal-flex-container'>
          <div class='modal-header'>

            <div>
              <h4>Edit Staff</h4>
            </div>

            <ButtonClose closeAction={closeModal} />

          </div>

          <div class='modal-body'>
            {formElement}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditStaff;