import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { isAuthed, saveToken } from '../utilities/auth/tokenHelpers';
import apiCall from '../../helperFunctions/apiCall';
import { signin } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import ButtonPrimary from '../utilities/buttonPrimary';
import InputPassword from '../utilities/forms/inputPassword';


function Login() {

  // Define variables
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');


  // Page load actions
  useEffect(() => {

    const loggedIn = isAuthed();
    if( loggedIn ) {
      navigate('/');
    }

  }, []);


  // Define functions
  function handleSubmit(event) {
    event.preventDefault();

    if( !email || !password ) {
      toast.error('Please enter your email and password');
      return;
    }

    setButtonLoading(true);

    const payload = {
      email: email,
      password: password
    };

    apiCall(signin, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        setTimeout(() => navigate('/'), 400);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setButtonLoading(null), 400);
    });
  }


  return(
    <div class='body-wrapper flex-column'>
      <Header />

      <div class='body-container'>
        <h1>Login</h1>

        <form onSubmit={handleSubmit}>

          <div class='field'>
            <input
              name="email"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              required
              placeholder='Email address'
              title="Enter your email address"
            />
          </div>

          <div class='field'>
            <InputPassword
              value={password}
              handleOnChange={setPassword}
            />
          </div>

          <ButtonPrimary
            value={'Continue'}
            loading={buttonLoading}
          />

        </form>
      </div>
    </div>
  );
}

export default Login;