import ProgressBar from "../progressBar";


function CourseUnhas(props) {

  // Define variables
  const courseData = props.courseData;
  const status = courseData.status.toLowerCase();


  // Screen components
  let courseProgressElement;

  if( status === 'not started') {

    courseProgressElement = (
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <ProgressBar percent={0} />
        <p class="gray-text seconday-info">
          Not started
        </p>
      </div>
    );

  } else if( status === 'active' ) {

    const courseProgressDecimal = courseData.courseLessonsCompleted / courseData.courseLessonTotal;
    const courseProgressPercent = Math.round(100 * courseProgressDecimal);

    courseProgressElement = (
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <ProgressBar percent={courseProgressPercent} />
        <p class="gray-text seconday-info">
          {courseData.courseLessonsCompleted}<span>/</span>{courseData.courseLessonTotal}
        </p>
      </div>
    );

  } else if( status === 'complete' ) {

    courseProgressElement = (
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <ProgressBar percent={100} />
        <p class="gray-text seconday-info">
          completed {courseData.completeDate}
        </p>
      </div>
    );
  }

  return (
    <div class='unhas-course-data'>
      <p>{courseData.title}</p>
      {courseProgressElement}
    </div>
  )
}

export default CourseUnhas;