function ButtonClose(props) {

  const closeAction = props.closeAction;

  return (
    <button
      onClick={closeAction}
      class='icon icon-cancel-fill'
      title='Close'
      style={{
        padding: '0 15px'
      }}
    />
  )
}

export default ButtonClose;