import React from 'react';
import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getLearner } from '../utilities/auth/authAPI';
import { Loading } from '../utilities/helpers';
import LessonData from './lessonData';
import ScoreData from './scoreData';
import ButtonClose from '../utilities/buttonClose';
import ViewLearnerLessons from './viewLearnerLessons';
import ViewLearnerCourses from './viewLearnerCourses';


function ViewLearner(props) {

  // Define variables
  const id = props.id;
  const closeModal = props.closeModal;
  const [activeTab, setActiveTab] = useState('Lessons');
  const [activeContent, setActiveContent] = useState(null);
  const [learner, setLearner] = useState({});
  const [lessons, setLessons] = useState([]);
  const headings = [
    {name: 'title', display: 'Title'},
    {name: 'complete', display: 'Completed', centered: true},
    {name: 'time', display: 'Time', centered: true},
    {name: 'retries', display: 'Retries', centered: true},
    {name: 'score', display: 'Score', centered: true},
    {name: 'date', display: 'Date', centered: true}
  ];


  // Page load actions
  useEffect(() => {

    const payload = {id: id};

    apiCall(getLearner, payload).then((response) => {

      if( response.success ) {
        setLearner(response.data);

        if( response?.data?.lessonData?.lessons ) {

          const lessonsArray = response.data.lessonData.lessons;
          const newLessonArray = [];

          lessonsArray.forEach((lesson, index) => {

            newLessonArray.push(
              {
                title: lesson.title,
                complete: lesson.complete,
                time: lesson.completedDuration,
                retries: lesson.retries,
                score: lesson.score,
                date: lesson.completedOn
              }
            )
          });

          setLessons(newLessonArray);

          setActiveContent(
            <ViewLearnerLessons
              headings={headings}
              data={newLessonArray}
              firstName={response.data.firstName}
            />
          );
        }


      } else {
        toast.error(response.error);
        closeModal();
      }
    });
  }, [id]);


  // Define functions
  function changeActiveContent(type) {
    setActiveTab(type);

    if( type === 'Lessons' ) {
      setActiveContent(
        <ViewLearnerLessons
          headings={headings}
          data={lessons}
          firstName={learner.firstName}
        />
      );
    } else if( type === 'Courses' ) {
      setActiveContent(
        <ViewLearnerCourses
          headings={headings}
          courseData={learner.courseData}
          firstName={learner.firstName}
        />
      );
    }
  }


  // Screen componets
  let content = (
    <div>
      <div class='modal-header'>
        <div></div>
        <div>
          <ButtonClose closeAction={closeModal} />
        </div>
      </div>
      <div class='modal-body'>
        <Loading />
      </div>
    </div>
  );

  if( learner.firstName ) {

    let phoneNumberElement;
    if( learner.phoneNumber ) {
      phoneNumberElement = (
        <p>
          <small>{learner.phoneNumber}</small>
        </p>
      );
    }

    content = (
      <div class='modal-flex-container'>
        <div class='modal-header'>

          <div>
            <h4>{learner.firstName} {learner.lastName}</h4>
            <p>{learner.email}</p>
            {phoneNumberElement}
            <p style={{marginTop: '-3px'}}>
              <small><i>{learner.subscriptionType} Membership</i></small>
            </p>
          </div>

          <div>

            <button
              class='icon icon-printer'
              onClick={() => window.print()}
              title='Print'
              style={{marginRight: '15px'}}
            />

            <ButtonClose closeAction={closeModal} />

          </div>

        </div>

        <div class='modal-body'>

          <div class='stats-container flex-row' style={{marginTop: '30px', marginBottom: '30px'}}>

            <LessonData
              data={learner.lessonOverTimeData}
              lessonsStarted={learner.lessonsStarted}
              lessonsCompleted={learner.lessonsCompleted}
              averageRetries={learner.averageRetries}
              averageScore={learner.averageScore}
            />

            <ScoreData
              data={learner.scoreData}
              score={learner.score}
            />

          </div>

          {/* {lessonContent} */}
          <div class='flex-row tabs-container'>

            <h3>
              <button
                type='button'
                class={activeTab === 'Lessons' ? 'tab active' : 'tab'}
                onClick={() => changeActiveContent('Lessons')}
              >
                Lessons
              </button>
            </h3>

            <h3>
              <button
                type='button'
                class={activeTab === 'Courses' ? 'tab active' : 'tab'}
                onClick={() => changeActiveContent('Courses')}
              >
              Courses
              </button>
            </h3>

          </div>

          {activeContent}

        </div>
      </div>
    );
  }


  return(
    <div class='modal-wrapper'>
      <div class='modal-container'>
        {content}
      </div>
    </div>
  );
}

export default ViewLearner;