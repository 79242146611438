import LineGraph from '../utilities/graphs/lineGraph';


function ScoreData(props) {

  // Define variables
  const data = props.data;
  const score = props.score;

  const datasets = [
    {
      label: 'FLOW Score',
      data: data.data,
      borderColor: 'rgb(120, 8, 168)',
      backgroundColor: 'rgba(120, 8, 168, 0.2)',
      borderWidth: 2,
      pointRadius: 0,
      pointBorderWidth: 1,
      pointHoverRadius: 10,
      tension: .35
    }
  ];

  const graph = (
    <LineGraph
      datasets={datasets}
      labels={data.labels}
      yConfig={{min: 0, max: 1000, gridDisplay: false}}
      xConfig={{maxTicksLimit: 10}}
    />
  );


  return(
    <div style={{width: '35%', boxSizing: 'border-box' }}>
      <div class='flex-row' style={{marginBottom: '5px'}}>
        <p><small><strong>Confidence Level:</strong> {score}</small></p>
      </div>

      <div class='border-element-round padding-primary'>
        {graph}
      </div>
    </div>
  );
}

export default ScoreData;