import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getGroup, removeLearner } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';
import Table from '../utilities/table/table';
import TableLearners from '../utilities/table/tableLearners';
import ViewLearner from './viewLearner';
import EditPassword from './editPassword';
import LessonData from './lessonData';


function Group() {

  // Define variables
  const { id } = useParams();
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';
  const [group, setGroup] = useState({name: 'Loading ...'});
  const [loadingData, setLoadingData] = useState(true);
  const [viewLearnerElement, setViewLearnerElement] = useState(null);
  const [editPasswordElement, setEditPasswordElement] = useState(null);
  const totalSeats = group.totalSeats;
  const adminNames = group.adminNames;

  let lessonsStarted = 0;
  let lessonsCompleted = 0;
  let averageRetries = 0;
  let averageScore = 0;
  let lessonOverTimeData;

  if( group.activityData ) {
    lessonsStarted = group.activityData.lessonsStarted;
    lessonsCompleted = group.activityData.lessonsCompleted;
    averageRetries = group.activityData.averageRetries;
    averageScore = group.activityData.averageScore;
    lessonOverTimeData = group.activityData.lessonOverTimeData;
  }

  const headings = [
    {name: 'id', display: 'ID'},
    {name: 'firstName', display: 'First Name'},
    {name: 'lastName', display: 'Last Name'},
    {name: 'email', display: 'Email'},
    // {name: 'lessonsStarted', display: 'Lessons Started', centered: true},
    {name: 'lessonsCompleted', display: 'Lessons Completed', centered: true},
    {name: 'averageRetries', display: 'Average Retries', centered: true},
    {name: 'averageScore', display: 'Average Score', centered: true},
    {name: 'score', display: 'Confidence Level', centered: true}
  ];

  if( group.promoCode?.toLowerCase().includes('jsaf') ) {
    const item = {name: 'organization', display: 'Org'};
    headings.splice(3, 0, item);
  }


  const headingsInvited = [
    {name: 'id', display: 'ID'},
    {name: 'firstName', display: 'First Name'},
    {name: 'lastName', display: 'Last Name'},
    {name: 'email', display: 'Email'},
    {name: 'phoneNumber', display: 'Phone Number'}
  ];


  // Page load actions
  useEffect(() => {
    loadGroupData();
  }, [id]);


  // Define functions
  function loadGroupData() {
    const payload = {id: id};

    apiCall(getGroup, payload).then((response) => {
      if( response.success ) {
        setGroup(response.data);
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setLoadingData(false);
    });
  }

  function viewLearner(id) {
    setViewLearnerElement(
      <ViewLearner
        id={id}
        closeModal={() => setViewLearnerElement(null)}
      />
    );
  }

  function editPassword(object) {
    setEditPasswordElement(
      <EditPassword
        object={object}
        closeModal={() => setEditPasswordElement(null)}
      />
    );
  }

  function removeFromSeat(id) {
    if (window.confirm('Are you sure you want to remove this person?')) {

      const payload = {id: id};

      apiCall(removeLearner, payload).then((response) => {
        if( response.success ) {
          toast.error('Learner successfully removed');
          loadGroupData();
        } else {
          toast.error(response.error);
        }
      });

    }
  }


  // Screen componets
  let registrationLinkElement;
  if( group.registrationLink ) {
    registrationLinkElement = (
      <p>
        <strong style={{display: 'inline-block', width: '160px'}}>Registration Link:</strong>
        {group.registrationLink}
        &nbsp;&nbsp;
        <button
          type='button'
          onClick={() => alert('Send this link to your students or coworkers. When they signup through this link, they will automatically be associated with this Group.')}
        >
          <span class='icon icon-info'></span>
        </button>
      </p>
    );
  }

  let invitedLearnersElement;
  if( group.invitedLearners?.length > 0 ) {
    invitedLearnersElement = (
      <div style={{marginTop: '90px'}}>
        <Table
          headings={headingsInvited}
          data={group.invitedLearners}
          title={'Invited Learners'}
          enableSearch={true}
          // removeSeatFunction={isSuperAdmin ? removeFromSeat : null}
          isLoading={loadingData}
          blankStateWording={'There are no learners in this group'}
        />
      </div>
    );
  }

  let totalSeatsElement;
  if( totalSeats ) {
    totalSeatsElement = <small> ( of {totalSeats} seats allocated )</small>;
  }

  let adminNamesElement;
  if( isSuperAdmin ) {
    adminNamesElement = (
      <p>
        <span style={{display: 'inline-block', width: '160px'}}>Assigned Managers:</span>
        {adminNames ? adminNames : <small><i>There are not any Managers assigned yet</i></small>}
      </p>
    );
  }

  let lessonGraphElement;
  if( lessonOverTimeData ) {
    lessonGraphElement = (
      <div style={{marginBottom: '60px'}}>
        <LessonData
          data={lessonOverTimeData}
          lessonsStarted={lessonsStarted}
          lessonsCompleted={lessonsCompleted}
          averageRetries={averageRetries}
          averageScore={averageScore}
        />
      </div>
    );
  }

  let editGroupElement;
  if( isSuperAdmin ) {
    editGroupElement = (
      <Link to={`/groups/${id}/edit`} class='edit-link'>
        <span class='icon icon-edit'></span>
        Edit Group
      </Link>
    );
  }


  return(
    <div class='body-wrapper flex-column'>

      {viewLearnerElement}
      {editPasswordElement}

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        <div style={{marginBottom: '45px'}}>

          <div class='flex-row'>
            <h2 style={{marginBottom: '10px'}}>{group.name}</h2>
            {editGroupElement}
          </div>

          <p>
            <strong style={{display: 'inline-block', width: '160px'}}>ID:</strong>{id}
          </p>

          {registrationLinkElement}
          {adminNamesElement}

          <div style={{marginTop: '15px'}}>
            <p>
              {group.learnerCount} {group.learnerCount === 1 ? 'Learner' : 'Learners'}
              {totalSeatsElement}
            </p>
            <p>
              {lessonsCompleted} {lessonsCompleted === 1 ? 'Lesson' : 'Lessons'} completed
            </p>
          </div>

        </div>

        {lessonGraphElement}

        <TableLearners
          headings={headings}
          data={group.learners ? group.learners : []}
          title={'Learners'}
          enableSearch={true}
          viewFunction={viewLearner}
          editPasswordFunction={editPassword}
          removeSeatFunction={isSuperAdmin ? removeFromSeat : null}
          isLoading={loadingData}
          blankStateWording={'There are no learners in this group'}
          showCourseActivityColumn={group.showCourseActivityColumn}
        />

        {/* <Table
          headings={headings}
          data={group.learners ? group.learners : []}
          title={'Learners'}
          enableSearch={true}
          viewFunction={viewLearner}
          editPasswordFunction={editPassword}
          removeSeatFunction={isSuperAdmin ? removeFromSeat : null}
          isLoading={loadingData}
          blankStateWording={'There are no learners in this group'}
        /> */}

        {invitedLearnersElement}

      </div>

      <Footer />

    </div>
  );
}

export default Group;