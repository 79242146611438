import * as FileSaver from 'file-saver';
const XLSX = require('sheetjs-style');


function ExcelDownloader(props) {

  // Define variables
  const headings = props.headings;
  const excelData = formatTitles(props.data, headings);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
  const fileName = props.filename || 'export';
  const fileExtension = '.xlsx';


  // Define functions
  async function exportToExcel() {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }


  return (
    <button
      type='button'
      class='button export'
      onClick={() => exportToExcel('','')}
    >Export</button>
  );

}

export default ExcelDownloader;


// --

function formatTitles(array, headings) {
  const newArray = [];

  array.forEach((item, index) => {
    const newObject = {}
    headings.forEach((heading, headingIndex) => {
      newObject[heading.display] = item[heading.name];
    });

    newArray.push(newObject);
  });

  return newArray;
}