import LineGraph from '../utilities/graphs/lineGraph';


function LessonData(props) {

  // Define variables
  const data = props.data;
  const lessonData = data?.data ? data.data : [];
  const lessonsStarted = props.lessonsStarted;
  const lessonsCompleted = props.lessonsCompleted;
  const averageRetries = props.averageRetries;
  const averageScore = props.averageScore;

  const datasets = [
    {
      label: 'Lessons Completed',
      data: lessonData,
      borderColor: 'rgb(120, 8, 168)',
      backgroundColor: 'rgba(120, 8, 168, 0.2)',
      borderWidth: 2,
      pointRadius: 0,
      pointBorderWidth: 1,
      pointHoverRadius: 10,
      tension: .35
    }
  ];

  // Find the max value
  const mostLessons = (Math.max(...lessonData));
  const yMax = (mostLessons > 1) ? (mostLessons + 1) : 2;

  const graph = (
    <LineGraph
      datasets={datasets}
      labels={data.labels}
      yConfig={{min: 0, suggestedMax: yMax, gridDisplay: false}}
      xConfig={{maxTicksLimit: 10}}
    />
  );


  return(
    <div style={{width: '60%', boxSizing: 'border-box' }}>
      <div class='flex-row' style={{marginBottom: '5px'}}>
        <p><small><strong>Lessons:</strong> {lessonsStarted} started, {lessonsCompleted} completed</small></p>
        <p><small><strong>Average Retries:</strong> {averageRetries}</small></p>
        <p><small><strong>Average Score:</strong> {averageScore}</small></p>
      </div>

      <div class='border-element-round padding-primary'>
        {graph}
      </div>
    </div>
  );
}

export default LessonData;