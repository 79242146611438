// https://remarkablemark.org/blog/2021/05/23/add-redux-to-react-typescript-app/

// import { configureStore } from '@reduxjs/toolkit';
import { createStore, combineReducers } from 'redux';

import profileSlice from './profile';

export const store = createStore(
  combineReducers({
    profile: profileSlice.reducer
  })
)