import React from 'react';
import { useState, useEffect } from 'react';

import TdBasic from './tdBasic';
import TdView from './tdView';
import TdEditPassword from './tdEditPassword';
import TdRemoveSeat from './tdRemoveSeat';
import TdEditInfo from './tdEditInfo';
import { Loading } from '../helpers';
import ExcelDownloader from '../excelDownloader';


function Table(props) {

  // Define variables
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const data = props.data;
  const title = props.title;
  const enableSearch = props.enableSearch || undefined;
  const viewFunction = props.viewFunction || undefined;
  const editPasswordFunction = props.editPasswordFunction || undefined;
  const editInfoFunction = props.editInfoFunction || undefined;
  const removeSeatFunction = props.removeSeatFunction || undefined;
  const headings = props.headings;
  const isLoading = props.isLoading;
  const filename = props.filename || 'export';


  // Page load actions
  useEffect(() => {
    setFilteredData(data);
  }, [data]);


  // Define functions
  function search(value) {
    setSearchText(value);

    let results = [];

    data.find(function(object) {
      for(var i=0; i<headings.length; i++) {

        const thisValueRaw = object[headings[i].name];
        const thisValue = thisValueRaw ? thisValueRaw.toString().toLowerCase() : '';

        if( thisValue.includes(value.toLowerCase()) ) {
          results.push(object);
          break;
        }
      }
    });

    if( results ) {
      setFilteredData(results);
    }

  }


  // Screen components
  let searchElement;
  if( enableSearch ) {
    searchElement = (
      <input
        name="search"
        type="text"
        value={searchText}
        onChange={event => search(event.target.value)}
        placeholder='Search'
        title="Type to search"
      />
    );
  }

  let titleElement;
  if( title ) {
    titleElement = <h3>{title}</h3>;
  }

  let loadingElement;
  if( isLoading ) {
    loadingElement = <td colSpan={'100'} class='td-blank-state'><Loading /></td>;
  }

  let blankStateElement;
  if( !isLoading && filteredData.length === 0 ) {
    blankStateElement = <td colSpan={'100'} class='td-blank-state'>{props.blankStateWording ? props.blankStateWording : 'There are no records'}</td>;
  }

  let downloadElement;
  if( data.length > 0 ) {
    downloadElement = <ExcelDownloader data={data} headings={headings} filename={filename} />;
  }


  return(
    <div class='table-wrapper'>

      <div class='flex-row' style={{marginBottom: '10px'}}>
        <div class='flex-row'>
          {titleElement}
          {downloadElement}
        </div>
        {searchElement}
      </div>

      <table class='table'>
        <thead>
          <tr>
            {headings.map((heading, index) =>
              <td  key={`th-td-${index}`} class={heading.centered ? 'centered' : null}>{heading.display}</td>
            )}
            {viewFunction ? <td class='td-icon'></td> : null }
            {editPasswordFunction ? <td class='td-icon'></td> : null }
            {editInfoFunction ? <td class='td-icon'></td> : null }
            {removeSeatFunction ? <td class='td-icon'></td> : null }
          </tr>
        </thead>

        <tbody>
          {filteredData.map((object, index) =>
            <tr key={`tr-${index}`}>
              {headings.map((heading, index) =>
                <TdBasic
                  key={`td-${index}`}
                  heading={heading}
                  object={object}
                />
              )}

              {viewFunction ? <TdView id={object.id} viewFunction={viewFunction} /> : null }
              {editPasswordFunction ? <TdEditPassword object={object} editPasswordFunction={editPasswordFunction} /> : null }
              {editInfoFunction ? <TdEditInfo object={object} editInfoFunction={editInfoFunction} /> : null }
              {removeSeatFunction ? <TdRemoveSeat id={object.id} removeSeatFunction={removeSeatFunction} /> : null }

            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            {loadingElement}
            {blankStateElement}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default Table;