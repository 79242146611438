import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createStaff } from '../utilities/auth/authAPI';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import FormStaff from './form';
import OrganizationCard from '../utilities/organizationCard';


function AddStaff() {

  // Define variables
  const navigate = useNavigate();
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';

  const staffObject = {
    firstName: '',
    lastName: '',
    email: '',
    assignedGroups: []
  }


  // Page load actions
  useEffect(() => {
    if( !isSuperAdmin ) { navigate('/') }
  }, []);


  return(
    <div class='flex-column'>

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        <h2>Add Staff</h2>

        <FormStaff
          apiMethod={createStaff}
          staffObject={staffObject}
        />

      </div>

      <Footer />

    </div>
  );
}

export default AddStaff;