import ProgressBar from "../progressBar";


function CourseActivityBlock(props) {

  const title = props.title;
  const percent = props.percent;
  const lessonComplete = props.lessonComplete;
  const lessonTotal = props.lessonTotal;


  return (
    <div>
      <p>{title}</p>
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <ProgressBar percent={percent} />
        <p class="gray-text seconday-info">
          {lessonComplete}<span>/</span>{lessonTotal}
        </p>
      </div>
    </div>
  );
}

export default CourseActivityBlock;