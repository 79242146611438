import { Navigate } from 'react-router-dom'
import { isAuthed } from './tokenHelpers';

export const PrivateRoute = ({ component: RouteComponent }) => {
  const isAuthenticated = isAuthed();

  if (isAuthenticated) {
    return <RouteComponent />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;