import TdView from './tdView';
import TdEditPassword from './tdEditPassword';
import TdRemoveSeat from './tdRemoveSeat';
import TdEditInfo from './tdEditInfo';
// import ProgressBar from '../progressBar';
import CourseDataCell from './courseDataCell';


function TableLearnersRow(props) {

  // Define variables
  const object = props.object;
  const viewFunction = props.viewFunction || undefined;
  const editPasswordFunction = props.editPasswordFunction || undefined;
  const editInfoFunction = props.editInfoFunction || undefined;
  const removeSeatFunction = props.removeSeatFunction || undefined;
  const courseData = object.courseData;
  const pathwayData = object.pathwayData;
  const showCourseActivityColumn = props.showCourseActivityColumn;


  // Screen components
  let courseDataElement;
  if( showCourseActivityColumn ) {
    courseDataElement = <CourseDataCell courseData={courseData} pathwayData={pathwayData} />;
  }

  let lastLessonCompleteDateText = '---';
  if( object.lastLessonCompleteDate ) {
    lastLessonCompleteDateText = object.lastLessonCompleteDateFormatted;
  }

  let phoneNumberElement;
  if( object.phoneNumber ) {
    phoneNumberElement = <p>{object.phoneNumber}</p>;
  }


  return (
    <div class='flex-row table-learner-row'>

      <div class='table-learner-row-data'>
        <p><strong>{object.firstName} {object.lastName}</strong></p>
        <p>{object.email}</p>
        {phoneNumberElement}
        <p>{object.score} Confidence Level</p>
        <p><small><i>{object.subscriptionType} Membership</i></small></p>
      </div>

      {courseDataElement}

      <div class='table-learner-row-data'>
        <p>{object.lessonsCompleted} completed</p>
        <p class='gray-text'>{object.averageScore} average score</p>
        <p class='gray-text'>{object.averageRetries} average retries</p>
        <p class='gray-text'>
          <small>Last lesson on {lastLessonCompleteDateText}</small>
        </p>
      </div>

      <div class='table-learner-row-actions'>
        {viewFunction ? <TdView id={object.id} viewFunction={viewFunction} /> : null }
        {editPasswordFunction ? <TdEditPassword object={object} editPasswordFunction={editPasswordFunction} /> : null }
        {editInfoFunction ? <TdEditInfo object={object} editInfoFunction={editInfoFunction} /> : null }
        {removeSeatFunction ? <TdRemoveSeat id={object.id} removeSeatFunction={removeSeatFunction} /> : null }
      </div>

    </div>
  );
}

export default TableLearnersRow;